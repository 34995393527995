import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/style.css';
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false
Vue.use(ElementUI)

/* 路由发生变化修改页面title    路由守卫*/  
router.beforeEach((to,form,next) => {
  if(to.meta.title) {
    document.title = to.meta.title
  }
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  // 兼容IE
  window.scrollTo(0,0);
  next()
  // 判断是否登录，登录就放行登录后的页面。
  // if (to.meta.requiresAuth) {
  //   if (getToken()) {
  //     next()
  //   } else {
  //     next('/?r=' + to.path)
  //   }
  // }else {
  //   next()
  // }
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
