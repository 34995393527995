<template>
  <div id="app">
      <router-view></router-view>
      <footer-menu></footer-menu>
  </div>
</template>

<script>
import FooterMenu from './components/footerMenu.vue';

export default {
  name: 'App',
  components: {
    FooterMenu
  }
}
</script>

<style>

</style>
