<template>
    <div class="footer-box">
        <div class="contanir flex-c">
            <div>版权所有：济宁中安职业培训学校有限责任公司</div>
            <div class="m-l-20">备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index">鲁ICP备2023021468号</a></div>
        </div>
    </div>
</template>

<script>
export default {
    name:'footerMenu',
    methods:{
        
    }
}
</script>

<style lang="scss" scoped>
    .footer-box{
        width: 100%;
        height: 81px;
        background: #f8f8f8;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        .contanir{
            width: 62.5%;
            height: 100%;
            margin: 0 auto;
            font-size: 12px;
            color: #666;
        }
    }
</style>